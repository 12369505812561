<template>
  <div class="w-screen h-screen">
    <div
      id="mapbox"
      class="w-screen h-screen"
      :class="{
        'pointer-events-none': mapInteractionDisabled,
        'marker-interaction-disabled': markerInteractionDisabled,
      }"
    ></div>
    <transition name="fade">
      <div
        v-show="markerInteractionDisabled"
        class="absolute top-0 w-screen h-screen bg-gray-600 pointer-events-none bg-opacity-40"
      ></div>
    </transition>
    <transition name="slide-bottom">
      <div
        v-show="!markerInteractionDisabled"
        class="absolute bottom-0 flex items-end w-screen px-4 py-3 pointer-events-none"
      >
        <div class="pointer-events-auto">
          <a href="https://www.mapbox.com/" target="_blank">
            <img src="img/mapbox-logo.svg" style="width: 84px;" />
          </a>
        </div>
        <div class="flex-grow"></div>
        <div class="text-white pointer-events-auto" style="font-size: 12px">
          <a href="https://www.mapbox.com/about/maps/">© Mapbox / </a>
          <a href="http://www.openstreetmap.org/copyright">© OpenStreetMap / </a>
          <a href="https://www.mapbox.com/map-feedback/" target="_blank"
            ><strong>Improve this map</strong></a
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

export default {
  props: { mapCenterLocationId: String },

  setup(props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const mapInteractionDisabled = ref(false)
    const markerInteractionDisabled = ref(false)

    let map
    let markers = []
    let selectedMarker
    let newLocationMarker

    onMounted(() => {
      initMap(store.getters.locations)
    })

    watch(
      () => route.name,
      name => {
        markerInteractionDisabled.value = name === 'home' ? false : true
      },
    )

    store.watch(
      () => store.getters.selectedLocation,
      location => {
        if (location && store.getters.mapLoaded) selectMarker(location.id)
        else deselectMarker()
      },
    )

    store.watch(
      () => store.getters.mapAction,
      payload => {
        console.log('Map action triggered: ' + payload.action)
        const action = payload.action
        const data = payload.data
        switch (action) {
          case 'addMarker':
            addMarker(data.location)
            break
          case 'showMarker':
            showMarker(markers[data.id])
            break
          case 'hideMarker':
            hideMarker(markers[data.id])
            break
          case 'moveMarker':
            moveMarker(data.id, data.coordinates)
            break
          case 'deleteMarker':
            deleteMarker(data.id)
            break
          case 'setStyle':
            setStyle(data)
            break
        }
      },
    )

    store.watch(
      () => store.getters.newLocationPos,
      payload => {
        if (payload) setNewLocationMarkerPos(payload.longitude, payload.latitude, payload.flyTo)
        else hideMarker(newLocationMarker)
      },
    )

    const getMapboxStyle = styleName => {
      switch (styleName) {
        case 'normal':
          return 'mapbox://styles/bjornarb/cklqzdt9j00ol17ljd1eu3vhn'
        case 'satellite':
          return 'mapbox://styles/mapbox/satellite-streets-v11'
      }
    }

    const setStyle = styleName => {
      map.setStyle(getMapboxStyle(styleName))
    }

    const addMarkers = locations => {
      locations.forEach(location => {
        addMarker(location)
      })
    }

    const addMarker = location => {
      const marker = new mapboxgl.Marker()
      const markerElement = marker.getElement()
      marker.setLngLat([location.coordinates.longitude, location.coordinates.latitude])
      markerElement.addEventListener('touchstart', event => {
        event.preventDefault()
        let selectedLocation = store.getters.selectedLocation
        if (!selectedLocation || selectedLocation.id !== location.id)
          store.commit('selectLocation', location.id)
        else store.commit('selectLocation', null)
      })
      markerElement.addEventListener('mouseenter', () => {
        store.commit('selectLocation', location.id)
      })
      markerElement.addEventListener('mouseleave', () => {
        if (route.name === 'home') store.commit('selectLocation', null) // prevent triggering deselect location on location expand
      })
      markerElement.addEventListener('click', event => {
        event.stopPropagation()
        router.push({ name: 'location', params: { id: location.id, transition: 'zoom-in' } })
      })
      marker.addTo(map)
      markers[location.id] = marker
    }

    const showMarker = marker => {
      const el = marker.getElement()
      el.style.opacity = 1
    }

    const hideMarker = marker => {
      const el = marker.getElement()
      el.style.opacity = 0
    }

    const selectMarker = id => {
      if (selectedMarker) deselectMarker()
      selectedMarker = markers[id]
      setMarkerColor(selectedMarker, 'yellow')
      commitSelectedMarkerPos()
    }

    const deselectMarker = () => {
      if (!selectedMarker) return
      setMarkerColor(selectedMarker, '#3FB1CE')
      selectedMarker = undefined
    }

    const setMarkerColor = (marker, color) => {
      const el = marker.getElement()
      el.querySelectorAll('svg g[fill="' + marker._color + '"]')[0].setAttribute('fill', color)
      marker._color = color
    }

    const commitSelectedMarkerPos = () => {
      if (!selectedMarker) return
      const el = selectedMarker.getElement()
      const pos = el.getBoundingClientRect()
      store.commit('selectedLocationPos', {
        x: pos.left + el.offsetWidth / 2,
        y: pos.top,
      })
    }

    const moveMarker = (id, coordinates) => {
      const marker = markers[id]
      marker.setLngLat([coordinates.longitude, coordinates.latitude])
    }

    const deleteMarker = id => {
      const marker = markers[id]
      marker.remove()
    }

    const setNewLocationMarkerPos = (longitude, latitude, flyTo) => {
      newLocationMarker.setLngLat([longitude, latitude])
      if (flyTo || map.getZoom() < 16) {
        mapInteractionDisabled.value = true
        map.flyTo({ center: [longitude, latitude], zoom: 16 })
      }
      showMarker(newLocationMarker)
    }

    const initMap = locations => {
      let centerLocation
      if (props.mapCenterLocationId) {
        store.commit('selectLocation', props.mapCenterLocationId)
        centerLocation = store.getters.selectedLocation
      } else if (store.getters.locations.length > 0) {
        centerLocation = store.getters.randomLocation()
      } else {
        centerLocation = {
          coordinates: {
            latitude: 58.96808498031541,
            longitude: 5.871549854724739,
          },
        }
      }

      mapboxgl.accessToken =
        'pk.eyJ1IjoiYmpvcm5hcmIiLCJhIjoiY2tscXphbTh2MTdxbjJwcW1kZHF2djFiaiJ9.UENZGTAR8-pyP0ywdjbZNw'
      map = new mapboxgl.Map({
        container: 'mapbox',
        style: getMapboxStyle(store.state.mapStyle),
        center: [centerLocation.coordinates.longitude, centerLocation.coordinates.latitude],
        zoom: 14,
      })
      map.dragRotate.disable()
      map.doubleClickZoom.disable()
      map.touchZoomRotate.disableRotation()
      map.on('click', event => {
        console.log('Map was clicked')
        if (route.name === 'new' || route.name === 'edit')
          store.commit('newLocationPos', {
            longitude: event.lngLat.lng,
            latitude: event.lngLat.lat,
            flyTo: false,
          })
        else {
          store.commit('selectLocation', null)
        }
      })
      map.on('drag', () => {
        commitSelectedMarkerPos()
      })
      map.on('move', () => {
        if (!map.dragPan.isActive()) commitSelectedMarkerPos()
      })
      map.on('moveend', () => {
        mapInteractionDisabled.value = false
      })
      map.on('sourcedata', event => {
        if (event.isSourceLoaded && !store.getters.mapLoaded) {
          addMarkers(locations)
          store.commit('mapLoaded', true)
          if (props.mapCenterLocationId) selectMarker(props.mapCenterLocationId)
        }
      })

      newLocationMarker = new mapboxgl.Marker({ color: '#ff0000' })
        .setLngLat([centerLocation.coordinates.longitude, centerLocation.coordinates.latitude])
        .addTo(map)
      hideMarker(newLocationMarker)

      const geocoder = new MapboxGeocoder({
        marker: false,
        mapboxgl: mapboxgl,
        accessToken: mapboxgl.accessToken,
        placeholder: 'Søk etter sted...',
      })
      document.getElementById('geocoder').appendChild(geocoder.onAdd(map))
    }

    return {
      mapInteractionDisabled,
      markerInteractionDisabled,
    }
  },
}
</script>

<style>
.mapboxgl-marker {
  cursor: pointer;
}

.marker-interaction-disabled .mapboxgl-marker {
  pointer-events: none;
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
  margin-left: 16px;
  margin-bottom: 16px;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  margin-right: 16px;
  margin-bottom: 16px;
}
</style>
