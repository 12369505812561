import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/functions'
import { firebaseStorageUrlToPath } from './helpers'

export const userActions = {
  createUser({ commit, dispatch }, payload) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      let user
      firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then(userCredential => {
          user = userCredential.user
          if (payload.photo != null) {
            return dispatch('uploadUserPhoto', { id: user.uid, image: payload.photo })
          } else if (payload.photoURL != null) {
            return payload.photoURL
          } else {
            return ''
          }
        })
        .then(photoURL => {
          return user.updateProfile({
            photoURL,
            displayName: payload.displayName,
          })
        })
        .then(() => {
          resolve()
          commit('setLoading', false)
        })
        .catch(error => {
          reject(error)
          commit('setLoading', false)
        })
    })
  },

  async updateUser({ commit, dispatch }, payload) {
    commit('setLoading', true)
    const user = firebase.auth().currentUser
    let profileChanges = {}
    try {
      if (payload.photoDeleted) {
        if (user.photoURL !== '' && !user.photoURL.includes('gravatar.com')) {
          await dispatch('deleteUserPhoto', user.photoURL)
        }
        profileChanges.photoURL = ''
      }
      if (payload.photo != null) {
        profileChanges.photoURL = await dispatch('uploadUserPhoto', {
          id: user.uid,
          image: payload.photo,
        })
      }
      if (payload.photoURL != null) {
        profileChanges.photoURL = payload.photoURL
      }
      if (payload.displayName != user.displayName) {
        profileChanges.displayName = payload.displayName
      }
      if (profileChanges.photoURL != null || profileChanges.displayName != null) {
        await user.updateProfile(profileChanges)
      }
      if (payload.email !== user.email) {
        const credential = firebase.auth.EmailAuthProvider.credential(user.email, payload.password)
        await user.reauthenticateWithCredential(credential)
        await user.updateEmail(payload.email)
      }
      commit('setLoading', false)
    } catch (err) {
      commit('setLoading', false)
      return Promise.reject(err)
    }
  },

  async deleteUser({ commit }, password) {
    commit('setLoading', true)
    const user = firebase.auth().currentUser
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, password)
    try {
      await user.reauthenticateWithCredential(credential)
      await user.delete()
      commit('setLoading', false)
    } catch (err) {
      commit('setLoading', false)
      return Promise.reject(err)
    }
  },

  signIn({ commit }, payload) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(() => {
          commit('setLoading', false)
          resolve()
        })
        .catch(error => {
          commit('setLoading', false)
          reject(error)
        })
    })
  },

  signOut({ commit }) {
    commit('setLoading', true)
    firebase
      .auth()
      .signOut()
      .then(() => {
        commit('setLoading', false)
        commit('setUser', null)
      })
      .catch(error => {
        commit('setLoading', false)
        console.log('Error signing out: ', error)
      })
  },

  changePassword({ commit }, payload) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser
      const credential = firebase.auth.EmailAuthProvider.credential(user.email, payload.password)
      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          return user.updatePassword(payload.passwordNew)
        })
        .then(() => {
          commit('setLoading', false)
          resolve()
        })
        .catch(error => {
          commit('setLoading', false)
          reject(error)
        })
    })
  },

  sendPasswordResetEmail({ commit }, email) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      firebase.auth().useDeviceLanguage()
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          commit('setLoading', false)
          resolve()
        })
        .catch(error => {
          commit('setLoading', false)
          reject(error)
        })
    })
  },

  uploadUserPhoto({ commit }, payload) {
    commit('setLoading', true)
    commit('asyncStatusMessage', 'Laster opp bilde')
    const id = payload.id
    const image = payload.image
    return new Promise((resolve, reject) => {
      const filename = image.name
      const extension = filename.slice(filename.lastIndexOf('.'))
      const path = 'tmp/' + id + extension
      firebase
        .storage()
        .ref()
        .child(path)
        .put(image)
        .then(snapshot => {
          commit('asyncStatusMessage', 'Komprimerer bilde')
          const scaleUserPhoto = firebase
            .app()
            .functions('europe-west1')
            .httpsCallable('scaleUserPhoto')
          return scaleUserPhoto({
            bucket: snapshot.ref.bucket,
            fullPath: snapshot.ref.fullPath,
          })
        })
        .then(snapshot => {
          commit('setLoading', false)
          resolve(snapshot.data.url)
        })
        .catch(error => {
          commit('setLoading', false)
          reject(error)
        })
    })
  },

  async deleteUserPhoto({ commit }, url) {
    commit('setLoading', true)
    commit('asyncStatusMessage', 'Sletter brukerbilde')
    try {
      const fullPath = firebaseStorageUrlToPath(url)
      await firebase
        .storage()
        .ref()
        .child(fullPath)
        .delete()
      commit('setLoading', false)
      return Promise.resolve() // TODO: bad practice?
    } catch (err) {
      commit('setLoading', false)
      return Promise.reject(err) // TODO: bad practice?
    }
  },

  getGravatarUrl({ commit }, email) {
    commit('setLoading', true)
    const generateGravatarUrl = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('generateGravatarUrl')
    return generateGravatarUrl({ email })
  },
}
