<template>
  <svg height="240" width="240">
    <g class="logo">
      <g class="left-pole"><line x1="0" y1="0" x2="0" y2="200" /></g>
      <g class="right-pole"><line x1="0" y1="0" x2="0" y2="200" /></g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style>
.logo {
  opacity: 0;
  /* animation: slide-in 1000ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1400ms forwards; */
  animation: slide-in2 1000ms 200ms forwards;
}

line {
  stroke: white;
  stroke-width: 12;
}

@keyframes slide-in {
  0% {
    transform: translate(120px, 240px);
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(120px, 30px);
  }
}

@keyframes slide-in2 {
  0% {
    transform: translate(120px, 240px);
  }

  24% {
    transform: translate(120px, 14px);
  }

  34% {
    opacity: 1;
    transform: translate(120px, 36px);
  }

  48% {
    opacity: 1;
    transform: translate(120px, 16px);
  }

  75% {
    opacity: 1;
    transform: translate(120px, 33px);
  }

  100% {
    opacity: 1;
    transform: translate(120px, 30px);
  }
}

.left-pole {
  animation: left-pole-animation 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 300ms forwards;
}

@keyframes left-pole-animation {
  100% {
    transform: translate(20px, 0) rotate(30deg);
  }
}

.right-pole {
  animation: right-pole-animation 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 300ms forwards;
}

@keyframes right-pole-animation {
  100% {
    transform: translate(-20px, 0) rotate(-30deg);
  }
}
</style>
