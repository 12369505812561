import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/functions'
import { firebaseStorageUrlToPath } from './helpers'

export const locationActions = {
  loadLocations({ commit }) {
    return firebase
      .firestore()
      .collection('locations')
      .get()
      .then(snapshot => {
        const locations = []
        snapshot.forEach(doc => {
          const location = doc.data()
          location.id = doc.id
          locations.push(location)
        })
        commit('setLocations', locations)
      })
  },

  saveLocation({ commit, dispatch }, payload) {
    commit('asyncStatusMessage', 'Lagrer plass')

    let id
    const image = payload.image
    const user = firebase.auth().currentUser
    const location = {
      title: payload.title,
      features: payload.features,
      description: payload.description,
      coordinates: new firebase.firestore.GeoPoint(
        payload.coordinates.latitude,
        payload.coordinates.longitude,
      ),
      created: firebase.firestore.FieldValue.serverTimestamp(),
      userId: user.uid,
      userName: user.displayName,
      userPhoto: user.photoURL,
    }

    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('locations')
        .add(location)
        .then(docRef => {
          id = docRef.id
          return dispatch('uploadImage', { id, image })
        })
        .then(() => {
          return firebase
            .firestore()
            .collection('locations')
            .doc(id)
            .get()
        })
        .then(snapshot => {
          let newLocation = snapshot.data()
          newLocation.id = id
          commit('newLocation', newLocation)
          commit('mapAction', { action: 'addMarker', data: { location: newLocation } })
          commit('asyncStatusMessage', 'Ferdig!')
          resolve(id)
        })
        .catch(error => {
          reject()
          console.error('Unable to save location: ', error)
        })
    })
  },

  updateLocation({ commit, dispatch }, payload) {
    commit('selectLocation', null)
    commit('asyncStatusMessage', 'Lagrer endringer')

    const id = payload.id
    const image = payload.image
    const images = payload.images
    const location = {
      title: payload.title,
      features: payload.features,
      description: payload.description,
      coordinates: new firebase.firestore.GeoPoint(
        payload.coordinates.latitude,
        payload.coordinates.longitude,
      ),
    }

    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('locations')
        .doc(id)
        .update(location)
        .then(() => {
          if (!image) return
          console.log('A new image was selected, deleting old images first')
          return dispatch('deleteImages', images)
        })
        .then(() => {
          if (!image) return
          console.log('Uploading new image')
          return dispatch('uploadImage', { id, image })
        })
        .then(() => {
          console.log('Get updated location from database')
          return firebase
            .firestore()
            .collection('locations')
            .doc(id)
            .get()
        })
        .then(snapshot => {
          console.log('Got updated location from database')
          let newLocation = snapshot.data()
          newLocation.id = id
          commit('mapAction', {
            action: 'moveMarker',
            data: { id, coordinates: newLocation.coordinates },
          })
          commit('updateLocation', newLocation)
          commit('asyncStatusMessage', 'Ferdig!')
          resolve(id)
        })
        .catch(error => {
          reject()
          console.error('Unable to save location: ', error)
        })
    })
  },

  uploadImage({ commit }, payload) {
    commit('asyncStatusMessage', 'Laster opp bilde')
    const id = payload.id
    const image = payload.image
    return new Promise((resolve, reject) => {
      const filename = image.name
      const extension = filename.slice(filename.lastIndexOf('.'))
      const path = 'locations/uploads/' + id + extension
      firebase
        .storage()
        .ref()
        .child(path)
        .put(image)
        .then(snapshot => {
          commit('asyncStatusMessage', 'Komprimerer bilde')
          const scaleLocationImage = firebase
            .app()
            .functions('europe-west1')
            .httpsCallable('scaleLocationImage')
          return scaleLocationImage({
            locationId: id,
            userName: firebase.auth().currentUser.displayName,
            imageUrl: snapshot.ref.fullPath,
          })
        })
        .then(snapshot => {
          console.log('Saving image urls to db')
          const images = snapshot.data
          images.original = path
          return firebase
            .firestore()
            .collection('locations')
            .doc(id)
            .update({ images })
        })
        .then(() => {
          console.log('Image uploaded and scaled sucessfully')
          resolve()
        })
        .catch(error => {
          console.error('Error uploading image', error.message)
          reject()
        })
    })
  },

  deleteImages({ commit }, images) {
    commit('asyncStatusMessage', 'Sletter bilder')

    const fullPath = firebaseStorageUrlToPath(images.full)
    const thumbPath = firebaseStorageUrlToPath(images.thumb)
    const originalPath = images.original

    return new Promise((resolve, reject) => {
      console.log('Deleting images')
      const deleteImageFull = firebase
        .storage()
        .ref()
        .child(fullPath)
        .delete()
      const deleteImageThumb = firebase
        .storage()
        .ref()
        .child(thumbPath)
        .delete()
      const deleteImageOriginal = firebase
        .storage()
        .ref()
        .child(originalPath)
        .delete()
      Promise.all([deleteImageFull, deleteImageThumb, deleteImageOriginal])
        .then(() => {
          console.log('All images was deleted')
          resolve()
        })
        .catch(error => {
          console.error('One or more images was not deleted', error.message)
          reject()
        })
    })
  },

  deleteLocation({ dispatch, commit }, payload) {
    commit('selectLocation', null)

    const id = payload.id
    const images = payload.images

    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('locations')
        .doc(id)
        .delete()
        .then(() => {
          console.log('Location deleted from database, now delete images')
          return dispatch('deleteImages', images)
        })
        .then(() => {
          console.log('Location successfully deleted!')
          commit('deleteLocation', id)
          commit('mapAction', { action: 'deleteMarker', data: { id } })
          resolve()
        })
        .catch(error => {
          console.error('Error deleting location: ', error)
          reject()
        })
    })
  },

  addToFavourites({ commit }, payload) {
    commit('asyncStatusMessage', 'Legger til favoritt')
    const favourite = {
      id: payload.id,
      title: payload.title,
      image: payload.images.full,
    }
    const user = firebase.auth().currentUser
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('user-settings')
        .where('user-id', '==', user.uid)
        .get()
        .then(snapshot => {
          const doc = snapshot.docs[0]
          const id = doc.id
          return firebase
            .firestore()
            .collection('user-settings')
            .doc(id)
            .update({
              favourites: firebase.firestore.FieldValue.arrayUnion(favourite),
            })
        })
        .then(snapshot => {
          console.log('done', snapshot)
          commit('asyncStatusMessage', 'Ferdig!')
          resolve()
        })
        .catch(error => {
          reject()
          console.error('Unable to add favourite: ', error)
        })
    })
  },

  removeFromFavourites({ commit }, payload) {
    commit('asyncStatusMessage', 'Fjerner favoritt')
    const favourite = {
      id: payload.id,
      title: payload.title,
      image: payload.images.full,
    }
    const user = firebase.auth().currentUser
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('user-settings')
        .where('user-id', '==', user.uid)
        .get()
        .then(snapshot => {
          const doc = snapshot.docs[0]
          const id = doc.id
          return firebase
            .firestore()
            .collection('user-settings')
            .doc(id)
            .update({
              favourites: firebase.firestore.FieldValue.arrayRemove(favourite),
            })
        })
        .then(snapshot => {
          console.log('Favourite removed successfully', snapshot)
          commit('asyncStatusMessage', 'Ferdig!')
          resolve()
        })
        .catch(error => {
          reject()
          console.error('Unable to remove favourite: ', error)
        })
    })
  },
}
