<template>
  <div
    class="flex flex-col items-center justify-center w-screen h-screen bg-gray-200"
    style="background-color: #5DA9A3"
  >
    <animated-logo></animated-logo>
  </div>
</template>

<script>
import AnimatedLogo from '../components/AnimatedLogo.vue'

export default {
  components: { AnimatedLogo },
}
</script>

<style></style>
