import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import './assets/tailwind.css'

const firebaseConfig = {
  apiKey: 'AIzaSyCfAutqv_DDdJzKuqeYqY4GEeO2QQs49Q0',
  authDomain: 'telt-43738.firebaseapp.com',
  projectId: 'telt-43738',
  storageBucket: 'telt-43738.appspot.com',
  messagingSenderId: '62964504576',
  appId: '1:62964504576:web:2d7653778ac26ced2be0e5',
  measurementId: 'G-QQMFZRTXC6',
}

firebase.initializeApp(firebaseConfig)

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe()
      store.commit('setUser', user)
      resolve(user)
    }, reject)
  })
}

createApp(App)
  .use(store)
  .use(router)
  .mount('#app')
