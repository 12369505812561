<template>
  <!-- Map -->
  <mapbox
    v-if="locationsLoaded"
    :mapCenterLocationId="mapCenterLocationId"
    class="absolute top-0 w-screen h-screen"
  ></mapbox>

  <!-- Location preview -->
  <location-preview></location-preview>

  <!-- Map search -->
  <transition name="slide-top">
    <div
      v-show="searchVisible"
      class="absolute flex justify-center w-screen px-4 pointer-events-none top-14"
      id="geocoder"
    ></div>
  </transition>

  <!-- Navigation -->
  <transition name="slide-bottom">
    <div
      class="absolute flex justify-center w-screen space-x-2 pointer-events-none bottom-10"
      v-show="$route.name === 'home'"
    >
      <button
        class="bg-gray-100 rounded-full pointer-events-auto w-14 h-14"
        @click="toggleSearch()"
      >
        <i class="fa-light fa-magnifying-glass fa-lg"></i>
      </button>
      <button class="bg-gray-100 rounded-full pointer-events-auto w-14 h-14">
        <i class="fa-light fa-location-arrow fa-lg"></i>
      </button>
      <button
        @click="$router.push({ name: 'settings', params: { transition: 'slide-bottom' } })"
        class="bg-gray-100 rounded-full pointer-events-auto w-14 h-14"
      >
        <i class="fa-light fa-sliders fa-lg"></i>
      </button>
      <button
        @click="$router.push({ name: 'menu', params: { transition: 'slide-bottom' } })"
        class="bg-gray-100 rounded-full pointer-events-auto w-14 h-14"
      >
        <i class="fa-light fa-bars fa-lg"></i>
      </button>
    </div>
  </transition>

  <!-- Views -->
  <div class="absolute top-0 w-screen h-screen pointer-events-none card">
    <router-view v-slot="{ Component }">
      <transition
        :name="transitionName"
        :mode="transitionMode"
        @after-enter="transitionInEnded = true"
        @after-leave="transitionOutEnded"
      >
        <component :is="Component" :transitionInEnded="transitionInEnded"></component>
      </transition>
    </router-view>
  </div>

  <!-- Loading screen -->
  <transition name="fade">
    <loading-screen class="absolute top-0 w-screen h-screen" v-if="!mapLoaded"></loading-screen>
  </transition>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import Mapbox from './components/Mapbox.vue'
import LoadingScreen from './components/LoadingScreen.vue'
import LocationPreview from './components/LocationPreview.vue'

export default {
  components: { Mapbox, LocationPreview, LoadingScreen },

  setup() {
    const store = useStore()
    const router = useRouter()

    const transitionName = ref('')
    const transitionMode = ref('')
    const transitionInEnded = ref(false)
    const locationsLoaded = ref(false)
    const mapCenterLocationId = ref(null)

    router.beforeEach((to, from, next) => {
      if (to.params.transition) transitionName.value = to.params.transition
      else transitionName.value = 'slide-backward'

      if (
        (from.name === 'location' && to.name === 'location details') ||
        (from.name === 'location details' && to.name === 'location')
      ) {
        transitionMode.value = 'out-in'
      } else {
        transitionMode.value = ''
      }

      if (!store.getters.locations) {
        store.dispatch('loadLocations').then(() => {
          locationsLoaded.value = true
          mapCenterLocationId.value = to.params.id
          next()
        })
      } else {
        next()
      }
    })

    const transitionOutEnded = () => {
      transitionInEnded.value = false
    }

    const toggleSearch = () => {
      store.commit('searchVisible', !store.getters.searchVisible)
    }

    return {
      toggleSearch,
      transitionName,
      transitionMode,
      transitionInEnded,
      transitionOutEnded,
      mapLoaded: computed(() => store.getters.mapLoaded),
      searchVisible: computed(() => store.getters.searchVisible),
      locationsLoaded,
      mapCenterLocationId,
    }
  },
}
</script>

<style>
body {
  background-color: #5da9a3;
}

#app,
button {
  color: #333;
  font-family: 'Avenir', Helvetica, sans-serif;
  font-family: 'IBM Plex Sans', sans-serif;
}

*:focus {
  outline: none !important;
}

.card {
  perspective: 1200px;
  transform: translateZ(0);
}

.card-side {
  will-change: transform;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}

/* Slide forward transition */
.slide-forward-enter-active,
.slide-forward-leave-active {
  position: absolute;
  transition: all 300ms ease-out;
}

.slide-forward-enter-from {
  opacity: 0;
  transform: translateX(100px);
}

.slide-forward-leave-active {
  opacity: 0;
  transform: translateX(-100px);
}

/* Slide backward transition */
.slide-backward-enter-active,
.slide-backward-leave-active {
  transition: all 300ms ease-out;
}

.slide-backward-enter-from {
  opacity: 0;
  transform: translateX(-100px);
}

.slide-backward-leave-active {
  opacity: 0;
  transform: translateX(100px);
}

/* Slide top transition */
.slide-top-enter-active,
.slide-top-leave-active {
  transition: all 300ms ease-in-out;
}

.slide-top-enter-from,
.slide-top-leave-active {
  opacity: 0;
  transform: translateY(-50px);
}

/* Slide top sm transition */
.slide-top-sm-enter-active,
.slide-top-sm-leave-active {
  transition: all 160ms ease-in-out;
}

.slide-top-sm-enter-from,
.slide-top-sm-leave-active {
  opacity: 0;
  transform: translateY(-16px);
}

/* Slide bottom transition */
.slide-bottom-enter-active,
.slide-bottom-leave-active {
  transition: all 300ms ease-in-out;
  transition-property: opacity, transform;
}

.slide-bottom-enter-from,
.slide-bottom-leave-active {
  opacity: 0;
  transform: translateY(50px);
}

/* Slide bottom sm transition */
.slide-bottom-sm-enter-active,
.slide-bottom-sm-leave-active {
  transition: all 200ms ease-in-out;
  transition-property: opacity, transform;
}

.slide-bottom-sm-enter-from,
.slide-bottom-sm-leave-active {
  opacity: 0;
  transform: translateY(14px);
}

/* Spin forward transition */
.spin-forward-enter-active {
  transition: all 250ms ease-out;
}

.spin-forward-leave-active {
  transition: all 250ms ease-in;
}

.spin-forward-enter-from {
  transform: translateZ(-160px) rotateY(90deg);
}

.spin-forward-leave-active {
  transform: translateZ(-160px) rotateY(-90deg);
}

/* Spin forward 90 transition */
.spin-forward-90-enter-active {
  transition: all 250ms ease-out;
}

.spin-forward-90-leave-active {
  transition: all 250ms ease-in;
}

.spin-forward-90-enter-from {
  transform: rotateY(90deg);
}

.spin-forward-90-leave-active {
  transform: rotateY(-90deg);
}

/* Spin backward transition */
.spin-backward-enter-active {
  transition: all 250ms ease-out;
}

.spin-backward-leave-active {
  transition: all 250ms ease-in;
}

.spin-backward-enter-from {
  transform: translateZ(-160px) rotateY(-90deg);
}

.spin-backward-leave-active {
  transform: translateZ(-160px) rotateY(90deg);
}

/* Spin backward 90 transition */
.spin-backward-90-enter-active {
  transition: all 250ms ease-out;
}

.spin-backward-90-leave-active {
  transition: all 250ms ease-in;
}

.spin-backward-90-enter-from {
  transform: rotateY(-90deg);
}

.spin-backward-90-leave-active {
  transform: rotateY(90deg);
}

/* Zoom in transition */
.zoom-in-enter-active,
.zoom-in-leave-active {
  transition: all 250ms ease-in-out;
  transition-property: opacity, transform;
}

.zoom-in-enter-from,
.zoom-in-leave-active {
  opacity: 0;
  transform: scale(0.8);
}

/* Zoom out transition */
.zoom-out-enter-active,
.zoom-out-leave-active {
  transition: all 250ms ease-in-out;
  transition-property: opacity, transform;
}

.zoom-out-enter-from,
.zoom-out-leave-active {
  opacity: 0;
  transform: scale(1.2);
}
</style>
