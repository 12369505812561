export const mutations = {
  setUser(state, payload) {
    state.user = payload
  },

  setLocations(state, payload) {
    state.locations = payload
  },

  newLocation(state, payload) {
    state.locations.push(payload)
  },

  newLocationPos(state, payload) {
    state.newLocationPos = payload
  },

  updateLocation(state, payload) {
    const index = state.locations.findIndex(item => item.id === payload.id)
    state.locations[index] = payload
  },

  deleteLocation(state, id) {
    const index = state.locations.findIndex(item => item.id === id)
    state.locations.splice(index, 1)
  },

  selectLocation(state, payload) {
    state.selectedLocation = state.locations.find(location => location.id === payload)
  },

  selectedLocationPos(state, payload) {
    state.selectedLocationPos = payload
  },

  setMapStyle(state, payload) {
    state.mapStyle = payload
    localStorage.setItem('mapStyle', payload)
  },

  mapAction(state, payload) {
    state.mapAction = payload
  },

  mapLoaded(state, payload) {
    state.mapLoaded = payload
  },

  setLoading(state, payload) {
    state.loading = payload
  },

  asyncStatusMessage(state, payload) {
    state.asyncStatusMessage = payload
  },

  searchVisible(state, payload) {
    state.searchVisible = payload
  },
}
