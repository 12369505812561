<template>
  <div class="home">
    <!-- <button @click="Logout">Logout</button> -->
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

import { ref, onBeforeMount } from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  setup() {
    const name = ref('')

    onBeforeMount(() => {
      const user = firebase.auth().currentUser
      if (user) {
        name.value = user.displayName
      }
    })

    const Logout = () => {
      firebase
        .auth()
        .signOut()
        .then(() => console.log('Signed out'))
        .catch(err => alert(err.message))
    }

    return {
      name,
      Logout,
    }
  },

  // name: 'Home',
  // components: {
  //   HelloWorld
  // }
}
</script>
