export const state = {
  user: null,
  userSettings: null,
  locations: null,
  mapStyle: localStorage.getItem('mapStyle') || 'normal',
  mapAction: null,
  mapLoaded: false,
  newLocationPos: {
    latitude: null,
    longitude: null,
    flyTo: false,
  },
  selectedLocation: null,
  selectedLocationPos: null,
  searchVisible: false,
  loading: false,
  error: null,
  asyncStatusMessage: null,
  isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  ),
}
