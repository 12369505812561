import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import firebase from 'firebase/app'
import 'firebase/auth'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => import('../views/Intro.vue'),
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('../views/UserSignIn.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/UserRegister.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/UserEdit.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/user-confirmation',
    name: 'user-confirmation',
    component: () => import('../views/UserEditConfirmation.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/delete-user',
    name: 'delete-user',
    component: () => import('../views/UserDelete.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('../views/UserPasswordEdit.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/UserPasswordReset.vue'),
  },
  {
    path: '/guidelines',
    name: 'guidelines',
    component: () => import('../views/Guidelines.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/new',
    name: 'new',
    component: () => import('../views/Form.vue'),
    props: { isNew: true },
    meta: { requiresAuth: true },
  },
  {
    path: '/:id/edit',
    name: 'edit',
    component: () => import('../views/Form.vue'),
    props: { isNew: false },
    meta: { requiresAuth: true },
  },
  {
    path: '/:id/delete',
    name: 'delete',
    component: () => import('../views/Delete.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:id',
    name: 'location',
    component: () => import('../views/Location.vue'),
  },
  {
    path: '/:id/details',
    name: 'location details',
    component: () => import('../views/LocationDetails.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/Settings.vue'),
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../views/Menu.vue'),
  },
  {
    path: '/locations',
    name: 'locations',
    component: () => import('../views/Locations.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/Faq.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/Privacy.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, _from, next) => {
  const user = await firebase.getCurrentUser()
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (user == null && requiresAuth) next('menu')
  else next()
})

export default router
