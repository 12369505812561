export const getters = {
  user(state) {
    return state.user
  },

  locations(state) {
    return state.locations
  },

  locationById: state => id => {
    return state.locations.find(location => location.id === id) || { images: {} }
  },

  randomLocation: state => () => {
    const index = Math.floor(Math.random() * state.locations.length)
    return state.locations[index]
  },

  selectedLocation(state) {
    return state.selectedLocation
  },

  selectedLocationPos(state) {
    return state.selectedLocationPos
  },

  newLocationPos(state) {
    return state.newLocationPos
  },

  mapAction(state) {
    return state.mapAction
  },

  mapLoaded(state) {
    return state.mapLoaded
  },

  asyncStatusMessage(state) {
    return state.asyncStatusMessage
  },

  searchVisible(state) {
    return state.searchVisible
  },
}
