<template>
  <transition
    name="slide-bottom-sm"
    @before-enter="transitionInProgress = true"
    @after-enter="transitionInProgress = false"
    @before-leave="transitionInProgress = true"
    @after-leave="transitionOutEnded"
  >
    <div
      v-show="previewVisible"
      class="absolute cursor-pointer"
      :class="{ 'pointer-events-none': transitionInProgress, 'duration-75': closeFirst }"
      :style="{ left: previewPosition.x + 'px', top: previewPosition.y + 'px' }"
      @click="goToLocation"
    >
      <div class="absolute transform -translate-x-1/2 -translate-y-full w-44 -top-2">
        <div style="padding-top: 75%">
          <img
            class="absolute top-0 rounded-xl"
            v-show="previewLocation"
            :src="previewLocation ? previewLocation.images.thumb : ''"
            @load="imageLoadedHandler()"
            alt="Location image"
          />
        </div>
        <transition name="slide-top-sm">
          <div v-show="previewVisible" class="absolute px-2 py-1 mx-4 bg-white -top-3 text-md">
            {{ previewLocation != null ? previewLocation.title : '' }}
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const previewVisible = ref(false)
    const previewPosition = ref({})
    const previewLocation = ref(null)
    const closeFirst = ref(false)
    const transitionInProgress = ref(false)

    let tempPos
    let tempLocation

    watch(
      () => route.name,
      () => {
        if (route.name === 'home' && store.state.selectedLocation != null)
          previewVisible.value = true
        else previewVisible.value = false
      },
    )

    watch(
      () => store.state.selectedLocation,
      location => {
        if (location == null) {
          // no location selected, hide preview
          previewVisible.value = false
          return
        }
        if (previewLocation.value != null && previewLocation.value.id === location.id) {
          // selected location has not changed, show preview immediately
          previewVisible.value = true
          return
        }
        if (previewVisible.value || transitionInProgress.value) {
          // new selected location but preview is already visible or in a transition, hide first then show
          previewVisible.value = false
          tempLocation = location
          closeFirst.value = true
          return
        }
        // new selected location and preview is not visible
        previewLocation.value = location
      },
    )

    store.watch(
      () => store.getters.selectedLocationPos,
      pos => {
        if (closeFirst.value) tempPos = pos
        else previewPosition.value = pos
      },
    )

    const goToLocation = () => {
      router.push({
        name: 'location',
        params: { id: previewLocation.value.id, transition: 'zoom-in' },
      })
    }

    const imageLoadedHandler = () => {
      if (store.getters.selectedLocation != null) previewVisible.value = true
    }

    const transitionOutEnded = () => {
      if (closeFirst.value) {
        previewPosition.value = tempPos
        previewLocation.value = tempLocation
        closeFirst.value = false
      }
      transitionInProgress.value = false
    }

    return {
      previewVisible,
      previewPosition,
      previewLocation,
      transitionOutEnded,
      transitionInProgress,
      imageLoadedHandler,
      goToLocation,
      closeFirst,
    }
  },
}
</script>
